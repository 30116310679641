import React from "react";
import NewsInsights from "components/ES/Search/NewsInsights";
import Layout from "components/Layout";
import { PageContext } from "utils/context";
import Seo from "src/utils/seo"

const CourseFinderPage = ({ location }) => {
  const searchIndices = [{ name: `News_Insights`, title: `News_Insights` }];
  return (
    <PageContext.Provider
      value={{
        breadcrumb: '',
        location: location,
      }}
    >      
      <Seo pageName={'News and blog'} pageURL={'news-and-blog'} pageMeta={{description: 'Latest news, awards and student success at Escape Studios. Escape Studios blog posts, student stories and days in the life of VFX, Animation, Games.'}}/>
      <Layout location={location}>
          <NewsInsights indices={searchIndices} location={location}/>
      </Layout>
    </PageContext.Provider>
  );
};

export default CourseFinderPage;