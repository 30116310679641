import { createRef, default as React, useState, useMemo, useEffect, useRef } from "react"
import algoliasearch from "algoliasearch"
import { InstantSearch } from "react-instantsearch-dom"
import SearchBox from "./Components/SearchBox"
import SearchResult from "./Components/SearchResult"
import CurrentFilters from "./Components/CurrentFilters"
import CheckboxFilters from "./Components/CheckboxFilters"
import Pagination from "./Components/Pagination"
import persistantOrder from "./Components/persistantOrder"
import useClickOutside from "./Components/useClickOutside"
import Filters from './Components/Filters'
import qs from 'qs';
import { navigate } from "gatsby"

const NewsInsights = ({ indices, location }) => {

    const createURL = state => `/news-and-blog/?${qs.stringify(state)}`

    const searchStateToUrl = searchState =>
      searchState ? createURL(searchState) : '';

    const urlToSearchState = ({ search }) => { 
      return qs.parse(search.slice(1))
    };
    
    const rootRef = createRef()
    const [hasFocus, setFocus] = useState(false)
    const DEBOUNCE_TIME = 400
    const searchClient = useMemo(
      () =>
        algoliasearch(
          process.env.GATSBY_ALGOLIA_APP_ID,
          process.env.GATSBY_ALGOLIA_SEARCH_KEY
        ),
      []
    )
    useClickOutside(rootRef, () => setFocus(false))

    const [searchState, setSearchState] = useState(urlToSearchState(location));
    const debouncedSetStateRef = useRef(null);
  
    function onSearchStateChange(updatedSearchState) {

      clearTimeout(debouncedSetStateRef.current);

      debouncedSetStateRef.current = setTimeout(() => {
        navigate(searchStateToUrl(updatedSearchState));
      }, DEBOUNCE_TIME);
  
      setSearchState(updatedSearchState);
    }
  
    useEffect(() => {
      setSearchState(urlToSearchState(location));
    }, [location]);    

    return (
        <InstantSearch
            searchClient={searchClient}
            indexName={indices[0].name}
            searchState={searchState}
            onSearchStateChange={onSearchStateChange}
            createURL={createURL}
        >
          <div className="bg-black">
            <div className="container mx-auto pb-16 flex-grow w-full px-6">
              <h1 className="pt-12 lg:pb-12 pb-7 text-white font-condensed text-center font-bold uppercase lg:!text-[120px] md:!text-8xl xs:text-5.5xl text-4xl !leading-none tracking-[-0.2px]">
                News & Blog
              </h1>
              <SearchBox onFocus={() => setFocus(true)} hasFocus={hasFocus} className="relative mb-4 max-w-[977px] mx-auto"/>
              <Filters closeButtonText={'See results'}>
                <CurrentFilters />
                <CheckboxFilters 
                  title={"Search by content type"}
                  attribute="tag_list" 
                  transformItems={items => persistantOrder(items)}
                  limit={50}
                />
              </Filters>          
              <div className="lg:py-20 md:py-12 py-8 w-full">
                <SearchResult 
                  show={searchState && searchState.length > 0 && hasFocus} 
                  indices={indices}
                  noResultsText={`We didn't find any match for your search.`}
                />
              </div>
              <Pagination/>
            </div>
          </div>
        </InstantSearch>            
    )
}

export default NewsInsights